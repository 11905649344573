<template>
  <v-radio-group v-model="cardSelection" :label="labelText" class="card-selection">
    <v-radio v-for="(card, index) in completedCardList" :key="index" :value="index">
      <template v-slot:label>
        <span>{{ label(card) }}</span>
        <span class="card-info">
          <span>{{ caption(card) }}</span>
          <span v-if="showCardExpiration(card)"> - Exp. {{ card.expiration | formatShortDate }}</span>
          <span v-else-if="card.status === 'expired'" :class="{ 'notice-status': isCardSelected(index) }"> - {{ $t('components.card_selection.card_expired') }}</span>
          <span v-else-if="card.status === 'lost_stolen'" :class="{ 'notice-status': isCardSelected(index) }"> - {{ $t('components.card_selection.card_lost_stolen') }}</span>
        </span>
        <span class="card-info notice" v-show="!isApplication(card) && !showCardExpiration(card) && isCardSelected(index)">
          <span v-if="context === 'purchase'">{{ $t('components.card_selection.purchase_renew_required') }}</span>
          <span v-if="context === 'activation'">{{ $t('components.card_selection.activation_renew_required') }}</span>
        </span>
      </template>
    </v-radio>
  </v-radio-group>
</template>

<script>
export default {
  name: 'card-selection',
  props: {
    context: {
      type: String,
      default: 'purchase',
      validator: function (value) {
        return ['purchase', 'activation'].includes(value);
      },
    },
    labelText: {
      type: String,
      default: undefined,
    },
    cardList: {
      type: Array,
      required: true,
    },
    hasApplication: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    cardSelection() {
      this.$emit('change', this.completedCardList ? this.completedCardList[this.cardSelection] : null);
    },
  },
  computed: {
    completedCardList() {
      if (this.hasApplication) {
        return [{ card_program_type: 'application', cardholder_id: null }, ...this.cardList];
      }
      return this.cardList;
    },
  },
  data() {
    return {
      cardSelection: 0,
    };
  },
  methods: {
    isApplication(card) {
      return card.card_program_type === 'application';
    },
    showCardExpiration(card) {
      return ['active', 'pending_activation', 'locked', 'blocked'].includes(card.status);
    },
    isCardSelected(index) {
      return index === this.cardSelection;
    },
    label(card) {
      switch (card.card_program_type) {
        case 'gpr':
        case 'instant':
          return `${this.$options.filters.publicToken(card.public_token)} (${this.$options.filters.maskedPan(card.masked_pan)})`;
        case 'application':
          return this.$t('components.card_selection.card_type_label_application');
      }
    },
    caption(card) {
      switch (card.card_program_type) {
        case 'gpr':
          return `${this.$t('components.card_selection.card_type_label_gpr')}`;
        case 'instant':
          return `${this.$t('components.card_selection.card_type_label_instant')}`;
        case 'application':
          return this.$t('components.card_selection.card_type_caption_application');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-selection {
  max-height: 240px;
  overflow: auto;

  .card-info {
    line-height: 1.1;
    font-size: var(--font-size-caption);
    padding-top: 4px;
  }

  .notice {
    color: var(--color-error);
    max-width: calc(var(--font-size-caption) * 20);
  }

  .notice-status {
    color: var(--color-error);
  }

  ::v-deep legend {
    font-size: 16px;
    font-weight: 600;
  }

  &.v-input--selection-controls {
    ::v-deep .v-radio {
      justify-content: flex-start;
      align-items: flex-start;

      > .v-label {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}
</style>
