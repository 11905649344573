<template>
  <div class="base-store">
    <p v-if="storeComplementaryInfo" class="complementary-info">{{ storeComplementaryInfo }}</p>
    <div class="information-container">
      <p class="information">
        <span class="d-block d-md-inline">{{ store.category_name[currentLocale] }}</span>

        <span v-if="store.destination_id && !currentDestination" class="d-none d-md-inline"> - </span>
        <span v-if="store.destination_id && !currentDestination" class="d-block d-md-inline">{{ destination }}</span>

        <span v-if="information" class="d-none d-md-inline"> - </span>
        <span v-if="information" class="d-block d-md-inline">{{ information }}</span>
      </p>

      <div v-if="storeUrl" class="link-container">
        <h-link :href="storeUrl" target="_blank">{{ storeUrlText }}<v-icon>mdi-open-in-new</v-icon></h-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _get from 'lodash/get';

export default {
  name: 'base-store',
  props: {
    store: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('store', ['currentDestinations', 'currentDestination']),
    statusReady() {
      return this.store.transactional_status === 'ready';
    },
    destination() {
      if (!this.currentDestinations || !this.store.destination_id || !this.currentDestinations[this.store.destination_id]) {
        return '';
      }
      return this.currentDestinations[this.store.destination_id][`label_${this.currentLocale}`];
    },
    information() {
      if (this.store.type === 'place') {
        return `${this.$options.filters.address(this.store.location.address)}`;
      }
      if (this.store.type === 'web') {
        return `${this.$t('components.store_card.online')} (${this.store.location.display_url[this.currentLocale]})`;
      }
      if (this.store.branding.description[this.currentLocale] && this.store.branding.description[this.currentLocale] !== null) {
        return this.store.branding.description[this.currentLocale];
      }
      return undefined;
    },
    storeComplementaryInfo() {
      return _get(this.store, `complementary_info[${this.currentLocale}]`, null);
    },
    storeUrl() {
      if (this.store.type === 'web' && this.store.location.url[this.currentLocale]) {
        return this.store.location.url[this.currentLocale];
      }
      if (this.store.branding.url[this.currentLocale]) {
        return this.store.branding.url[this.currentLocale];
      }
      return undefined;
    },
    storeUrlText() {
      if (this.store.type === 'web') {
        return this.$t('components.store_card.visit_web_site_web');
      }
      if (this.store.branding.url[this.currentLocale]) {
        return this.$t('components.store_card.visit_web_site_place');
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.complementary-info {
  margin-bottom: var(--gap);
  font-style: italic;
}

.information-container {
  > * {
    margin-bottom: var(--gap);
  }

  :last-child {
    margin-bottom: 0;
  }

  @media ($md-min) {
    display: flex;
    align-items: flex-end;

    > * {
      margin-bottom: 0 !important;
    }
  }
}

.information {
  font-weight: 600;
  flex-grow: 1;
  padding-right: 12px;

  @media ($md-min) {
    padding-right: var(--gap-xsmall);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.link-container {
  @media ($md-min) {
    flex-shrink: 0;
    width: 280px;
    text-align: right;
  }
}
</style>
