<template>
  <fragment>
    <div class="instant-card-activation-view">
      <!-- Space Header -->
      <h-hero-banner-partner v-if="currentSpaceSlug" :img-source-mobile="currentBrandingHorizontalLogo" :img-source-desktop="currentBrandingHeroSmall"></h-hero-banner-partner>

      <h-btn @click="backSpace" color="text-primary" inverted class="back-btn">
        <v-icon>mdi-menu-left</v-icon>
        {{ this.$route.query.fromOfferId ? $t('components.instant_card_activation_view.back.offer') : $t('components.instant_card_activation_view.back.space') }}
      </h-btn>

      <div class="instant-card-activation-view-body" :class="{ 'with-border-bottom': !!currentFlowStep.nextBtnLabel }">
        <div class="instant-card-activation-view-content">
          <activation-flow-info v-if="!authenticated"></activation-flow-info>

          <!--  Login -->
          <div v-show="isCurrentStep('login')" class="auth-container">
            <swap-auth-and-register v-if="$vuetify.breakpoint.xsOnly" register-first />
            <auth-and-register v-else />
            <p class="h-caption">{{ $t('components.instant_card_activation_view.login_step.disclaimer') }}</p>
          </div>

          <div v-show="isCurrentStep('findCard')">
            <div class="step-title">
              <h2>{{ $t('components.instant_card_activation_view.step_1.instruction') }}</h2>
            </div>

            <find-card-step-form ref="findCard" @next="nextStep" />
          </div>

          <!-- Steps -->
          <v-stepper v-if="currentFlowStep.isStepper" v-model="currentStepNumber">
            <v-stepper-items>
              <v-stepper-content :step="(flow['foundMultiPublisherCard'] && flow['foundMultiPublisherCard'].stepNumber) || -1">
                <find-card-success-with-multipublisher-offer :selected-offer-publisher-id="selectedOfferPublisherId" v-if="hasInstantCardOffer" @selectedOfferPublisherId="changeSelectedOfferPublisherId"></find-card-success-with-multipublisher-offer>
              </v-stepper-content>

              <v-stepper-content :step="(flow['needToKnow'] && flow['needToKnow'].stepNumber) || -1">
                <div class="step-title">
                  <h2>{{ $t('components.instant_card_activation_view.step_2.instruction') }}</h2>
                </div>
                <div class="info-box">
                  <ul>
                    <li class="list-item">{{ $t('components.instant_card_activation_view.step_2.fact_1') }}</li>
                    <li class="list-item">{{ $t('components.instant_card_activation_view.step_2.fact_2') }}</li>
                    <li class="list-item">{{ $t('components.instant_card_activation_view.step_2.fact_3') }}</li>
                    <li class="list-item">{{ $t('components.instant_card_activation_view.step_2.fact_4') }}</li>
                    <li class="list-item">{{ $t('components.instant_card_activation_view.step_2.fact_5') }}</li>
                    <li class="list-item">{{ $t('components.instant_card_activation_view.step_2.fact_6') }}</li>
                  </ul>
                </div>
              </v-stepper-content>

              <v-stepper-content :step="(flow['activateCard'] && flow['activateCard'].stepNumber) || -1">
                <div class="step-title">
                  <h2>{{ $t('components.instant_card_activation_view.step_3.instruction') }}</h2>
                </div>
                <div class="info-box">
                  <p>{{ $t('components.instant_card_activation_view.step_3.info') }}.</p>
                  <ul>
                    <li class="list-item">{{ $t('components.instant_card_activation_view.step_3.info_fact_1') }}</li>
                    <li class="list-item">{{ $t('components.instant_card_activation_view.step_3.info_fact_2') }}</li>
                  </ul>
                </div>
                <activate-card-step-from ref="activateCard" @next="nextStep" />
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>

          <instant-card-all-set-modal v-model="allSetModalIsOpen" @next="nextStep" />

          <div v-if="isCurrentStep('storeView')">
            <div ref="category-switch-scrolling-anchor" :style="{ height: '0px' }"></div>

            <div class="step-title">
              <h2>{{ $t('components.instant_card_activation_view.step_4.instruction') }}</h2>
            </div>
            <store-list></store-list>
          </div>
        </div>
      </div>
    </div>
    <div v-if="currentFlowStep.nextBtnLabel" class="action-footer">
      <h-btn v-if="isCurrentStep('foundMultiPublisherCard')" @click="currentFlowStep.next" :disabled="!selectedOfferPublisherId">{{ $t(currentFlowStep.nextBtnLabel) }}</h-btn>
      <h-btn v-else-if="isCurrentStep('findCard')" @click="stepFindCardSubmit" :disabled="!stepFindCardValid" :loading="stepFindCardWorking">{{ $t(currentFlowStep.nextBtnLabel) }}</h-btn>
      <h-btn v-else-if="isCurrentStep('activateCard')" @click="activateCardSubmit" :disabled="!activateCardValid" :loading="activateCardWorking">{{ $t(currentFlowStep.nextBtnLabel) }}</h-btn>
      <h-btn v-else-if="isCurrentStep('storeView')" @click="openAllSetModal">{{ $t(currentFlowStep.nextBtnLabel) }}</h-btn>
      <h-btn v-else @click="currentFlowStep.next">{{ $t(currentFlowStep.nextBtnLabel) }}</h-btn>
    </div>
  </fragment>
</template>

<script>
import AuthAndRegister from '@/components/authentication/AuthAndRegister';
import SwapAuthAndRegister from '@/components/authentication/SwapAuthAndRegister';
import { mapActions, mapGetters } from 'vuex';
import FindCardStepForm from '@/components/instant-card-activation/FindCardStepForm';
import ActivateCardStepFrom from '@/components/instant-card-activation/ActivateCardStepFrom';
import ActivationFlowInfo from '@/components/instant-card-activation/ActivationFlowInfo.vue';
import { horizontalLogoImageHelper } from '@/composables/image-helper';
import InstantCardAllSetModal from '../../instant-card-activation/InstantCardAllSetModal.vue';
import safeExecute from '@/composables/safe-execute';
import FindCardSuccessWithMultipublisherOffer from '@/components/instant-card-activation/FindCardSuccessWithMultipublisherOffer';

import _get from 'lodash/get';
import _nth from 'lodash/nth';
import StoreList from '../../stores/StoreList.vue';

export default {
  name: 'instant-card-activation-view',
  components: {
    FindCardStepForm,
    ActivateCardStepFrom,
    InstantCardAllSetModal,
    AuthAndRegister,
    SwapAuthAndRegister,
    FindCardSuccessWithMultipublisherOffer,
    ActivationFlowInfo,
    StoreList,
  },
  setup(props, { root }) {
    const { execute, working } = safeExecute(root.$store);
    return {
      execute,
      working,
      horizontalLogoImageHelper,
    };
  },
  data() {
    return {
      isMounted: false,
      currentStepNumber: 1,
      allSetModalIsOpen: false,
      h_steppers: undefined,
      selectedOfferPublisherId: '',
      h_sequence: ['login', 'findCard'],
      flow: {
        login: {
          subTitles: 'components.instant_card_activation_view.subtitle.0',
          next: () => {
            this.nextInSequence();
          },
        },
        findCard: {
          subTitles: 'components.instant_card_activation_view.subtitle.1-2',
          subTitleSuccess: 'components.instant_card_activation_view.subtitle.1',
          nextBtnLabel: 'components.find_card_step_form.next',
          stepperLabel: 'components.instant_card_activation_view.step_1.title',
          next: () => {
            this.specifyFlow();
          },
        },
      },
      sequenceIndex: 0,
    };
  },
  created() {
    this.initCountryList();
  },
  title: (args) => args.$t('page_title.instant_card_activation'),
  mounted() {
    this.isMounted = true;
  },
  watch: {
    authenticated: {
      handler() {
        if (this.authenticated) {
          this.nextStep();
        } else {
          this.setLoginSequence();
        }
      },
      immediate: true,
    },
    currentFlowStep() {
      if (window.hj && this.currentSequence) {
        hj('stateChange', `/instant/${this.currentSequence}`);
      }
    },
  },
  computed: {
    steppersSteps() {
      return this.h_steppers;
    },
    currentSequence() {
      const currentSequence = _nth(this.h_sequence, this.sequenceIndex);
      if (!currentSequence) {
        console.log(`Warning! Current Step is out of bound. Index "${this.sequenceIndex}" in ${this.h_sequence}`); // eslint-disable-line no-console
      }

      return currentSequence;
    },
    currentFlowStep() {
      return _get(this.flow, this.currentSequence, null);
    },
    flowStep() {
      return (name) => this.flow[name];
    },
    stepFindCardWorking() {
      if (this.isMounted && this.authenticated) {
        return this.$refs.findCard.working;
      }
      return false;
    },
    stepFindCardValid() {
      if (this.isMounted && this.authenticated) {
        return this.$refs.findCard.valid;
      }
      return false;
    },
    activateCardWorking() {
      if (this.isMounted && this.authenticated) {
        return this.$refs.activateCard.working;
      }
      return false;
    },
    activateCardValid() {
      if (this.isMounted && this.authenticated) {
        return this.$refs.activateCard.valid;
      }
      return false;
    },
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('security', ['authenticated']),
    ...mapGetters('instantCard', [
      'instantCardOffer',
      'instantCardStructure',
      'instantCardActiveOffers',
      'isSinglePublisher',
      'isMultipleOffersInstantCard',
      'instantCardOfferPublisherStores',
      'hasInstantCardOffer',
      'instantCardProjectPartnerSpaceUrl',
      'instantCardPartnerId',
    ]),
    ...mapGetters('space', ['currentSpaceSlug', 'currentBrandingName', 'currentBrandingHeroSmall', 'currentBrandingHorizontalLogo']),
  },
  methods: {
    backSpace() {
      if (this.$route.query.fromOfferId) {
        this.$router.pushNoDuplicate({ name: 'spaceOfferActivation', params: { space: this.currentSpaceSlug, offer_id: this.$route.query.fromOfferId } });
      } else {
        this.$router.pushNoDuplicate({ name: 'spaceHome', params: { space: this.currentSpaceSlug } });
      }
    },
    changeSelectedOfferPublisherId(publisherId) {
      this.selectedOfferPublisherId = publisherId;
    },
    isCurrentStep(name) {
      return this.currentSequence === name;
    },
    specifyFlow() {
      if (this.instantCardStructure === 'blank') {
        this.$set(this.flow, 'needToKnow', {
          subTitles: 'components.instant_card_activation_view.subtitle.1-2',
          isStepper: true,
          nextBtnLabel: 'components.instant_card_activation_view.step_2.next',
          stepNumber: 1,
          stepperLabel: 'components.instant_card_activation_view.step_2.title',
          next: () => {
            this.currentStepNumber = 2;
            this.nextInSequence();
          },
        });

        this.$set(this.flow, 'activateCard', {
          subTitles: 'components.instant_card_activation_view.subtitle.3',
          isStepper: true,
          stepNumber: 2,
          stepperLabel: 'components.instant_card_activation_view.step_3.title',
          nextBtnLabel: 'components.activate_card_step_form.next',
          next: () => {
            if (this.hasInstantCardOffer) {
              this.getOfferPublisherStoreWithOptionalCategories();

              this.nextInSequence();
            }
          },
        });

        this.$set(this.flow, 'storeView', {
          subTitleSuccess: 'components.instant_card_activation_view.subtitle.4',
          stepperLabel: 'components.instant_card_activation_view.step_3.title',
          nextBtnLabel: 'components.instant_card_activation_view.step_4.next',
          next: () => {
            this.$router.pushNoDuplicate({ name: 'my-account' });
          },
        });

        this.h_sequence = this.h_sequence.concat(['needToKnow', 'activateCard', 'storeView']);
        this.h_steppers = ['needToKnow', 'activateCard'];
      }

      if (this.instantCardStructure === 'multiple') {
        this.$set(this.flow, 'needToKnow', {
          subTitles: 'components.instant_card_activation_view.subtitle.1-2',
          isStepper: true,
          nextBtnLabel: 'components.instant_card_activation_view.step_2.next',
          stepNumber: 1,
          stepperLabel: 'components.instant_card_activation_view.step_2.title',
          next: () => {
            this.currentStepNumber = 2;
            this.nextInSequence();
          },
        });

        this.$set(this.flow, 'activateCard', {
          subTitles: 'components.instant_card_activation_view.subtitle.3',
          isStepper: true,
          stepNumber: 2,
          stepperLabel: 'components.instant_card_activation_view.step_3.title',
          nextBtnLabel: 'components.activate_card_step_form.next',
          next: () => {
            if (this.hasInstantCardOffer) {
              if (this.isMultipleOffersInstantCard) {
                this.getMultipleOfferPublisherStoreWithOptionalCategories();
              } else {
                this.getOfferPublisherStoreWithOptionalCategories();
              }

              this.nextInSequence();
            }
          },
        });

        this.$set(this.flow, 'storeView', {
          subTitleSuccess: 'components.instant_card_activation_view.subtitle.4',
          stepperLabel: 'components.instant_card_activation_view.step_3.title',
          nextBtnLabel: 'components.instant_card_activation_view.step_4.next',
          next: () => {
            this.$router.pushNoDuplicate({ name: 'my-account' });
          },
        });

        this.h_sequence = this.h_sequence.concat(['needToKnow', 'activateCard', 'storeView']);
        this.h_steppers = ['needToKnow', 'activateCard'];
      }

      if (this.instantCardStructure === 'single' && !this.hasInstantCardOffer) {
        this.$set(this.flow, 'needToKnow', {
          subTitles: 'components.instant_card_activation_view.subtitle.1-2',
          isStepper: true,
          nextBtnLabel: 'components.instant_card_activation_view.step_2.next',
          stepNumber: 1,
          stepperLabel: 'components.instant_card_activation_view.step_2.title',
          next: () => {
            this.currentStepNumber = 2;
            this.nextInSequence();
          },
        });

        this.$set(this.flow, 'activateCard', {
          subTitles: 'components.instant_card_activation_view.subtitle.3',
          isStepper: true,
          stepNumber: 2,
          stepperLabel: 'components.instant_card_activation_view.step_3.title',
          nextBtnLabel: 'components.activate_card_step_form.next',
          next: () => {
            window.location.href = this.instantCardProjectPartnerSpaceUrl;
          },
        });

        this.h_sequence = this.h_sequence.concat(['needToKnow', 'activateCard']);
        this.h_steppers = ['needToKnow', 'activateCard'];
      }

      if (this.instantCardStructure === 'single' && this.hasInstantCardOffer && this.isSinglePublisher) {
        this.$set(this.flow, 'needToKnow', {
          subTitles: 'components.instant_card_activation_view.subtitle.1-2',
          isStepper: true,
          nextBtnLabel: 'components.instant_card_activation_view.step_2.next',
          stepNumber: 1,
          stepperLabel: 'components.instant_card_activation_view.step_2.title',
          next: () => {
            this.currentStepNumber = 2;
            this.nextInSequence();
          },
        });

        this.$set(this.flow, 'activateCard', {
          subTitles: 'components.instant_card_activation_view.subtitle.3',
          isStepper: true,
          stepNumber: 2,
          stepperLabel: 'components.instant_card_activation_view.step_3.title',
          nextBtnLabel: 'components.activate_card_step_form.next',
          next: () => {
            if (this.hasInstantCardOffer) {
              this.getOfferPublisherStoreWithOptionalCategories();

              this.nextInSequence();
            }
          },
        });

        this.$set(this.flow, 'storeView', {
          subTitleSuccess: 'components.instant_card_activation_view.subtitle.4',
          stepperLabel: 'components.instant_card_activation_view.step_3.title',
          nextBtnLabel: 'components.instant_card_activation_view.step_4.next',
          next: () => {
            this.$router.pushNoDuplicate({ name: 'my-account' });
          },
        });

        this.h_sequence = this.h_sequence.concat(['needToKnow', 'activateCard', 'storeView']);
        this.h_steppers = ['needToKnow', 'activateCard'];
      }

      if (this.instantCardStructure === 'single' && this.hasInstantCardOffer && !this.isSinglePublisher) {
        this.$set(this.flow, 'foundMultiPublisherCard', {
          subTitles: 'components.instant_card_activation_view.subtitle.1-2',
          isStepper: true,
          nextBtnLabel: 'components.instant_card_activation_view.step_2.next',
          stepNumber: 1,
          stepperLabel: 'components.instant_card_activation_view.step_chose_an_option.title',
          next: () => {
            this.currentStepNumber = 2;
            this.nextInSequence();
          },
        });

        this.$set(this.flow, 'needToKnow', {
          subTitles: 'components.instant_card_activation_view.subtitle.1-2',
          isStepper: true,
          nextBtnLabel: 'components.instant_card_activation_view.step_2.next',
          stepNumber: 2,
          stepperLabel: 'components.instant_card_activation_view.step_2.title',
          next: () => {
            this.currentStepNumber = 3;
            this.nextInSequence();
          },
        });

        this.$set(this.flow, 'activateCard', {
          subTitles: 'components.instant_card_activation_view.subtitle.3',
          isStepper: true,
          stepNumber: 3,
          stepperLabel: 'components.instant_card_activation_view.step_3.title',
          nextBtnLabel: 'components.activate_card_step_form.next',
          next: () => {
            if (this.hasInstantCardOffer) {
              this.getOfferPublisherStoreWithOptionalCategories();
              this.nextInSequence();
            }
          },
        });

        this.$set(this.flow, 'storeView', {
          subTitleSuccess: 'components.instant_card_activation_view.subtitle.4',
          nextBtnLabel: 'components.instant_card_activation_view.step_4.next',
          next: () => {
            this.$router.pushNoDuplicate({ name: 'my-account' });
          },
        });

        this.h_sequence = this.h_sequence.concat(['foundMultiPublisherCard', 'needToKnow', 'activateCard', 'storeView']);
        this.h_steppers = ['foundMultiPublisherCard', 'needToKnow', 'activateCard'];
      }

      this.nextInSequence();
    },
    getOfferPublisherStoreWithOptionalCategories() {
      this.dataReady = false;

      this.getOfferPublisherStore(null).finally(() => {
        this.dataReady = true;
      });
    },
    async getMultipleOfferPublisherStoreWithOptionalCategories() {
      this.dataReady = false;

      this.getOffersStores(null).finally(() => {
        this.dataReady = true;
      });
    },
    ...mapActions('instantCard', ['initCountryList']),
    ...mapActions('store', ['setOfferPublisherStoreMode', 'setOffersStoreMode']),
    stepFindCardSubmit() {
      this.$refs.findCard.findCard();
    },
    activateCardSubmit() {
      this.$refs.activateCard.activateCard(this.selectedOfferPublisherId);
    },
    nextStep() {
      this.scrollToTop();
      this.currentFlowStep.next();
    },
    nextInSequence() {
      this.sequenceIndex = Math.min(this.sequenceIndex + 1, this.h_sequence.length - 1);
    },
    setLoginSequence() {
      this.sequenceIndex = 0;
    },
    openAllSetModal() {
      this.allSetModalIsOpen = true;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },
    async getOfferPublisherStore() {
      this.setOfferPublisherStoreMode();

      return this.execute(
        {
          action: 'store/updateQuery',
        },
        { publisher_id: this.selectedOfferPublisherId || this.instantCardOffer.publisher_id, offer_id: this.instantCardOffer.id, partner_id: this.instantCardPartnerId },
      );
    },

    async getOffersStores() {
      this.setOffersStoreMode();

      const offer_ids = this.instantCardActiveOffers.map((item) => item.id);
      return this.execute(
        {
          action: 'store/updateQuery',
        },
        { offer_id: offer_ids.join(','), partner_id: this.instantCardPartnerId },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn.back-btn {
  min-width: 0 !important;
  height: 60px;
  padding: 0 24px;
  margin-top: var(--gap);
  margin-right: auto;
}

.instant-card-activation-view {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.instant-card-activation-view-body {
  background-color: var(--color-ultra-light);
  padding: var(--gap) var(--gap-page-side);

  @media ($md-min) {
    padding: var(--gap) var(--gap-page-side);
  }
}

.instant-card-activation-view-body.with-border-bottom {
  padding-bottom: 80px;
  @media ($md-min) {
    padding-bottom: 21vh;
  }
}

.instant-card-activation-view-content {
  flex: 1 0 auto;
}

.color-header {
  padding: var(--gap);
  margin: calc(var(--gap) * -1) calc(var(--gap) * -1) var(--gap);
  background: var(--color-primary) url('~@/assets/register_instant_bg.png') right center no-repeat;
  background-size: auto 100%;
  color: var(--color-ultra-light);
}

.auth-container {
  margin: 0 calc(var(--gap) * -1);

  p {
    margin: 0 var(--gap);
  }

  @media ($sm-min) {
    margin: 0;

    p {
      margin: 0;
    }
  }
}

.auth-and-register {
  margin: var(--gap-xlarge) 0;
}

.step-title {
  margin-bottom: var(--gap-large);
  width: 100%;
  text-align: center;

  h2 {
    padding-bottom: var(--gap-small);
    border-bottom: var(--border-thick) solid var(--color-border);
    display: inline-block;
  }
}

.finale-step {
  margin-top: var(--gap-xlarge);
}

::v-deep .v-stepper__content {
  padding: 0;
}

.find-card-step-form {
  padding-top: 40px;
  max-width: 368px;
  margin: 0 auto;
  text-align: center;

  @media ($md-min) {
    padding-bottom: 60px;
  }
}

.info-box {
  max-width: 560px;
  margin: 0 auto var(--gap-xlarge);
  padding: var(--gap);
  border: var(--border-thin) solid var(--color-border);
  border-radius: var(--border-radius);

  .list-item:not(:last-child) {
    margin-bottom: 4px;
  }
}
.instant-card-subheader {
  margin: 12px 0 var(--gap) 0;
}

.action-footer {
  z-index: var(--hello-z-index-footer-bar);
  position: fixed;
  bottom: 0px;
  border-top: var(--border-thin) solid var(--color-border-light);
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  max-width: var(--page-max-width);
  text-align: center;
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(4px);

  @media ($md-min) {
    padding-top: 20px;
    padding-bottom: 11vh;
  }

  .v-btn.v-btn--disabled {
    background-color: #e0e0e0 !important;
  }
}

.category-filter {
  position: sticky;
  padding-top: var(--gap-xsmall);
  background-color: var(--color-ultra-light);
}
</style>
