<template>
  <div class="home-offers-grid">
    <div class="home-offers-grid-title" v-if="$slots.title || title">
      <div class="grid-title">
        <slot name="title">
          <h2 v-if="title">{{ title }}</h2>
        </slot>
      </div>

      <h-link large @click="listAllAction(listAllMessage)" v-if="showListAll">
        {{ listAllLabel }}
      </h-link>
    </div>

    <slot name="backButton" />
    <grid-small-card v-if="offers.length >= 1 || showStayTunedCard">
      <component :is="offer.current_promotion ? 'home-page-offer-consumer-promotion-small-card' : 'home-page-offer-bonus-small-card'" :current_promotion="offer.current_promotion" :offer="offer" v-for="offer in offers" :key="offer.id"></component>

      <stay-tuned-small-card v-if="showStayTunedCard" />
    </grid-small-card>

    <div v-if="showEmptyOfferMessage" class="empty-offers-list">
      <div v-if="hasCategory">
        <h1>
          {{ $t('components.empty_offers_list_with_category.header') }}
        </h1>
        <div class="empty-offers-message">
          {{ $t('components.empty_offers_list_with_category.message') }}
        </div>
      </div>
      <div v-else>
        <h1>
          {{ $t('components.empty_offers_list.header') }}
        </h1>
        <div class="empty-offers-message">
          {{ $t('components.empty_offers_list.message') }}
        </div>
      </div>
    </div>

    <slot name="action"></slot>
  </div>
</template>

<script>
import GridSmallCard from '@/components/cards/small/GridSmallCard.vue';
import HomePageOfferBonusSmallCard from '../cards/small/HomePageOfferBonusSmallCard.vue';
import HomePageOfferConsumerPromotionSmallCard from '../cards/small/HomePageOfferConsumerPromotionSmallCard.vue';
import StayTunedSmallCard from '../cards/small/StayTunedSmallCard.vue';

export default {
  name: 'home-offers-grid',
  props: {
    offers: {
      type: Array,
      default: () => [],
    },
    showEmptyOfferMessage: {
      type: Boolean,
      default: false,
    },
    showStayTunedCard: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    category: {
      type: String,
    },
    showListAll: {
      type: Boolean,
      default: true,
    },
    listAllAction: {
      type: Function,
    },
    listAllMessage: {
      type: String,
    },
    listAllLabel: {
      type: String,
    },
  },
  components: {
    GridSmallCard,
    HomePageOfferBonusSmallCard,
    StayTunedSmallCard,
    HomePageOfferConsumerPromotionSmallCard,
  },
  computed: {
    hasCategory() {
      return !!this.category;
    },
  },
};
</script>

<style lang="scss" scoped>
.home-offers-grid {
  margin-bottom: calc(var(--gap) * 3);
}

.home-offers-grid-title {
  margin-bottom: var(--gap);
}

.grid-title {
  text-transform: uppercase;
  margin-bottom: 6px;
}

.empty-offers-list {
  padding: 10% 20%;
  background: var(--color-ultra-light);
}

.empty-offers-message {
  margin-top: var(--gap);
}
</style>
