<template>
  <div class="home-offers-grid">
    <div class="grid-title">
      <h2>{{ $t('components.home_offers_list.title') }}</h2>
      <p class="h-label-1 grid-title-subtitle">{{ $t('components.home_offers_list.subtitle') }}</p>
    </div>

    <grid-small-card v-if="hasOffers">
      <component :is="offer.current_promotion ? 'home-page-offer-consumer-promotion-small-card' : 'home-page-offer-bonus-small-card'" :current_promotion="offer.current_promotion" :offer="offer" v-for="offer in offers" :key="offer.id"></component>
    </grid-small-card>

    <grid-small-card v-if="!hasOffers">
      <stay-tuned-small-card />
    </grid-small-card>

    <div class="list-all-section">
      <h-btn large @click="listAllAction()" inverted outlined color="primary">
        {{ $t('components.home_offers_list.list_all') }}
      </h-btn>
    </div>
  </div>
</template>

<script>
import HomePageOfferBonusSmallCard from '@/components/cards/small/HomePageOfferBonusSmallCard.vue';
import HomePageOfferConsumerPromotionSmallCard from '../cards/small/HomePageOfferConsumerPromotionSmallCard.vue';

import StayTunedSmallCard from '@/components/cards/small/StayTunedSmallCard.vue';
import GridSmallCard from '../cards/small/GridSmallCard.vue';
export default {
  name: 'home-offers-by-region',
  props: {
    offers: {
      type: Array,
      required: true,
    },
    listAllAction: {
      type: Function,
    },
  },
  components: {
    HomePageOfferBonusSmallCard,
    HomePageOfferConsumerPromotionSmallCard,
    StayTunedSmallCard,
    GridSmallCard,
  },
  computed: {
    regions() {
      return Object.keys(this.offersByRegion);
    },
    hasOffers() {
      return this.offers && this.offers.length >= 1;
    },
    hasCategory() {
      return false;
    },
  },
  methods: {
    listAllByRegion(region) {
      this.$router.pushNoDuplicate({ name: 'homeRegion', params: { region }, query: { category: this.category } });
    },
  },
};
</script>

<style scoped lang="scss">
.home-offers-grid {
  margin-bottom: calc(var(--gap) * 2);
}

.grid-title {
  margin-bottom: var(--gap);
}

.grid-title-subtitle {
  color: var(--color-text-tertiary);
}

.grid-title h2 {
  text-transform: uppercase;
  margin-bottom: 6px;
}

.list-all-section {
  display: flex;
  justify-content: center;

  padding: calc(var(--gap) * 2);
}
</style>
